<template>
    <b-tabs>
        <b-tab title="Grunddata">
            <div v-if="form"
                 class="px-3">
                <!--Data-->
                <dl>
                    <dt>Egenkontroll - Position</dt>
                    <dd>
                        {{ form.controlSerialNumber }} - {{form.concatenatedNumbers}}
                    </dd>
                    <dt>Beskrivning</dt>
                    <dd>
                        {{ form.description ? form.description : '-' }}
                    </dd>
                    <dt v-if="!showAllInformation"></dt>
                    <dd v-if="!showAllInformation"
                        class="text-center">
                        <b-button variant="red"
                                  size="sm"
                                  class="border-gray-light"
                                  style="background-color: #e9ecef;color:black;"
                                  v-b-toggle.show-more-information>
                            <font-awesome-icon icon="chevron-down" /> Visa mer
                        </b-button>
                    </dd>
                    <b-collapse id="show-more-information" accordion="show-more-information" role="tabpanel">
                        <dt>Plats</dt>
                        <dd>
                            {{form.buildingPartName}} - {{ form.place ? form.place : '' }}
                        </dd>
                        <dt>Artikel</dt>
                        <dd>
                            <ArticleModalViewer v-if="form.articleId"
                                                :id="form.articleId"
                                                :clickable="true"
                                                :title="form.articleName" />
                        </dd>
                        <dt>Påbörjad</dt>
                        <dd>
                            {{ form.controlCreatedDate | datetime('yyyy-MM-dd HH:mm') }}
                        </dd>
                        <dt>Avslutad</dt>
                        <dd>
                            <span v-if="form.controlDoneDate">
                                {{ form.controlDoneDate | datetime('yyyy-MM-dd HH:mm')}}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </dd>
                    </b-collapse>
                </dl>
                <hr style="margin-bottom: 0.5rem;" />

                <!--Form-->

                <div style="margin-bottom: 0.7rem;">
                    <strong>Status:</strong> <ControlPositionStatusBall :status-id="form.statusId"
                                                                        :title="statusName"
                                                                        verticalAlign="top" /> {{statusName}}
                </div>

                <b-form @submit.prevent="onSidebarSubmit" class="pb-5">
                    <FileUploader ref="fileUploader"
                                  :entity-type="'controlPartPosition' | getEntityTypeIdFromText"
                                  :entity-id="form.id"
                                  :read-only="readOnly" />

                    <b-form-group id="input-group-lackDescription"
                                  label="Brist"
                                  label-for="input-lackDescription">
                        <b-form-textarea id="input-lackDescription"
                                         v-model="form.lackDescription"
                                         type="text"
                                         :disabled="isSubmitting || readOnly"
                                         :readonly="true"
                                         autocomplete="off"
                                         aria-describedby="input-lackDescription-help-block" />
                        <b-form-text id="input-lackDescription-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('LackDescription')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>
                    <div class="mt-2" v-if="form && lackHistoryTab.items">
                        <label>Kvarstående brister</label>
                        <b-table :items="lackHistoryTab.items"
                                 class="lack-history-tab--table"
                                 :fields="lackHistoryTab.fields"
                                 :sort-by.sync="lackHistoryTab.sortBy"
                                 :sort-desc="false"
                                 hover
                                 small
                                 striped>
                            <template #cell(concatenatedNumbers)="row">
                                <div class="overflow-hidden">
                                    {{ row.item.concatenatedNumbers }}
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <b-form-group id="input-group-fixedStatusDescription"
                                  label="Åtgärdstatus"
                                  label-for="input-fixedStatusDescription">
                        <b-form-input id="input-fixedStatusDescription"
                                      v-model="form.fixedStatusDescription"
                                      type="text"
                                      placeholder="Ange status"
                                      :disabled="isSubmitting || readOnly"
                                      autocomplete="off"
                                      aria-describedby="input-fixedStatusDescription-help-block"
                                      required
                                      maxlength="400" />
                        <b-form-text id="input-fixedStatusDescription-help-block">
                            <ul class="text-danger mb-0">
                                <li v-for="message in validationByKey('FixedStatusDescription')"
                                    :key="message">
                                    {{ message }}
                                </li>
                            </ul>
                        </b-form-text>
                    </b-form-group>

                    <b-form-group id="input-group-fixedDescription"
                                  label="Åtgärd"
                                  label-for="input-fixedDescription">
                        <b-form-textarea id="input-fixedDescription"
                                         v-model="form.fixedDescription"
                                         type="text"
                                         placeholder="Ange åtgärdsbeskrivning"
                                         :disabled="isSubmitting || readOnly"
                                         autocomplete="off"
                                         aria-describedby="input-fixedDescription-help-block" />
                    </b-form-group>

                    <b-alert :show="validationByKey('Other').length > 0"
                             variant="danger">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Other')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-alert>

                    <b-row v-if="!readOnly">
                        <b-col cols="12">
                            <b-form-checkbox v-model="shouldOpenMailModalAfterSave">Öppna "Mail"-dialog efter spara</b-form-checkbox>
                            <SendLackMailModal title="Skicka brist"
                                               :lackId="form.id"
                                               :show="sendLackMailModalIsOpen"
                                               v-on:closed="onMailModalClosed" />

                        </b-col>
                        <b-col sm="6" class="pt-2">
                            <b-button v-b-tooltip.hover
                                      variant="warning"
                                      class="btn-block"
                                      :disabled="isSubmitting"
                                      @click="setStatus(2)">
                                <font-awesome-icon v-if="isSubmitting"
                                                   icon="spinner"
                                                   spin />
                                Pågår
                            </b-button>
                        </b-col>
                        <b-col sm="6" class="pt-2">
                            <b-button v-b-tooltip.hover
                                      class="btn-block"
                                      variant="success"
                                      :disabled="isSubmitting"
                                      @click="setStatus(15)">
                                <font-awesome-icon v-if="isSubmitting"
                                                   icon="spinner"
                                                   spin />
                                Åtgärdad
                            </b-button>
                        </b-col>
                        <b-col cols="12" class="mt-3">
                            <b-button v-b-tooltip.hover.bottom
                                      title="Sparar utan att göra en ny statusförändring"
                                      variant="outline-primary"
                                      type="submit"
                                      class="btn-block"
                                      :disabled="isSubmitting">
                                <font-awesome-icon v-if="isSubmitting"
                                                   icon="spinner"
                                                   spin />
                                Spara
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </b-tab>
        <b-tab title="Position" lazy>
            <div v-if="form">
                <ViewControlPositionOnDrawing :positionId="form.id" />

                <ViewControlPositionOnDrawingModal :positionId="form.id">
                    <template v-slot:button="{openViewControlPositionOnDrawingModal}">
                        <b-button @click="openViewControlPositionOnDrawingModal"
                                  block
                                  variant="primary">
                            Visa större
                        </b-button>
                    </template>
                </ViewControlPositionOnDrawingModal>
            </div>
        </b-tab>
        <b-tab title="Logg" lazy>
            <div class="mt-2 mx-2" v-if="form">
                <h5>Skickade mail</h5>
                <LogTable :entityId="form.id"
                          :entityType="6"
                          :type="4"></LogTable>
            </div>

            <div class="mt-2 mx-2" v-if="form">
                <h4>Kvarstående brister</h4>
                <b-table :items="lackHistoryTab.items"
                         class="lack-history-tab--table"
                         :fields="lackHistoryTab.fields"
                         :sort-by.sync="lackHistoryTab.sortBy"
                         :sort-desc="false"
                         hover
                         small
                         striped>
                    <template #cell(concatenatedNumbers)="row">
                        <div class="overflow-hidden">
                            {{ row.item.concatenatedNumbers }}
                        </div>
                    </template>
                </b-table>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
    import { get, post } from '@/helpers/api';
    import ArticleModalViewer from '@/components/article/ArticleModalViewer';
    import FileUploader from '@/components/file/FileUploader';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';
    import {
        getStatusName
    } from '@/services/control-position-status.service';
    import SendLackMailModal from '@/components/control/SendLackMailModal';
    import ViewControlPositionOnDrawing from '@/components/control/ViewControlPositionOnDrawing';
    import ViewControlPositionOnDrawingModal from '@/components/control/ViewControlPositionOnDrawingModal';
    import LogTable from '@/components/LogTable';

    export default {
        name: 'SaveBuildingPositionLacks',
        props: {
            position: Object,
            positionId: Number,
            readOnly: {
                type: Boolean,
                default: false,
                required: false
            }
        },
        async mounted() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (collapseId === 'show-more-information')
                    this.showAllInformation = isJustShown;
            });

            if (this.position) {
                this.form = {
                    id: this.position.id,
                    concatenatedNumbers: this.position.concatenatedNumbers,
                    lackDescription: this.position.lackDescription,
                    description: this.position.description,
                    place: this.position.place,
                    articleId: this.position.articleId,
                    articleName: this.position.articleName,
                    fixedDescription: this.position.fixedDescription,
                    fixedStatusDescription: this.position.fixedStatusDescription,
                    controlCreatedDate: this.position.controlCreatedDate,
                    controlDoneDate: this.position.controlDoneDate,
                    controlSerialNumber: this.position.controlSerialNumber,
                    statusId: this.position.statusId,
                    buildingPartName: this.position.buildingPartName
                };

                this.statusName = this.getControlPositionStatusName(this.position.statusId);
            } else {
                if (this.positionId > 0) {
                    await this.getPosition();
                }
            }

            await this.getHistory();
        },
        components: {
            ArticleModalViewer,
            FileUploader,
            ControlPositionStatusBall,
            SendLackMailModal,
            ViewControlPositionOnDrawing,
            ViewControlPositionOnDrawingModal,
            LogTable
        },
        data() {
            return {
                form: null,
                statusName: null,
                isSubmitting: false,
                successMessage: '',
                validationErrors: null,
                showAllInformation: false,
                shouldOpenMailModalAfterSave: false,
                sendLackMailModalIsOpen: false,
                lackHistoryTab: {
                    items: null,
                    sortBy: 'statusChangedDateDisplayText',
                    fields: [
                        {
                            key: 'statusChangedDateDisplayText',
                            label: 'Kontrollerad',
                            sortable: true,
                            sortDirection: 'desc',
                            thStyle: { 'width': '50px' }
                        },
                        { key: 'lackDescription', label: 'Anteckning', sortable: false, },
                    ],
                },
            };
        },
        methods: {
            async getPosition() {
                await get('Control', `GetExtendedPositionById/${this.positionId}`)
                    .then((x) => {
                        this.form = x.data;
                        this.statusName = this.getControlPositionStatusName(x.data.statusId);
                    })
                    .catch((x) => {
                        alert('Något gick snett');
                    });
            },
            async setStatus(statusId) {
                await this.save(statusId);
            },
            async onSidebarSubmit() {
                await this.save();
            },
            onMailModalClosed() {
                this.onPositionUpdate();
            },
            onPositionUpdate() {
                this.$emit('positionUpdated', this.form);
            },
            async save(statusId) {
                this.validationErrors = null;
                this.isSubmitting = true;

                if (typeof statusId !== 'undefined')
                    this.form.statusId = statusId;

                await post('Control', `SetPositionStatusFixed/${this.form.id}`, this.form)
                    .then((x) => {
                        // Show mailmodal or emit.
                        if (this.shouldOpenMailModalAfterSave) {
                            this.sendLackMailModalIsOpen = true;
                        } else
                            this.onPositionUpdate();

                        this.isSubmitting = false;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                        this.isSubmitting = false;
                    });
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            getControlPositionStatusName(statusId) {
                return getStatusName(statusId);
            },
            async getHistory() {
                await get('Control', `GetPositionLackHistoryByControlPositionId/${this.form.id}`)
                    .then((x) => {
                        this.lackHistoryTab.items = x.data;
                    })
                    .catch((x) => {
                        alert('Något gick snett');
                    }); 
            }
        }
    };
</script>

<style scoped>
    dl > dd {
        word-break: break-all;
    }

    .lack-history-tab--table {
        font-size: small;
    }
</style>