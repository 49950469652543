<template>
  <v-select
    :id="`input-articles-${_uid}`"
    v-model="selected"
    label="name"
    :disabled="disabled"
    :filterable="false"
    :options="articles"
    ref="vms"
    @search="onSearch"
    @option:selected="onSelect"
  >
    <template slot="no-options">
      Sök efter artikel
    </template>
    <template
      slot="option"
      slot-scope="option">
      <div class="d-center">
        <img
          v-if="option.iconFileId"
          :src="'/api/File/Download/' + option.iconFileId"
        >
        <!--:src='option.imageAsBase64'-->
        {{ option.articleNo }} {{ option.name }}
      </div>
    </template>
    <template
      slot="selected-option"
      slot-scope="option"
    >
      <div class="selected d-center">
        <img :src="option.imageAsBase64">
        {{ option.articleNo }} {{ option.name }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { get } from "@/helpers/api";
import { debounce } from "@/helpers/input.helper";

export default {
  name: "ArticleSelectList",
  props: {
    disabled: Boolean,
    value: Number
  },
  data() {
    return {
      articles: [],
      selected: null,
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) this.selected = null;
    },
  },
  mounted() {
    // Get selected article.
    if (this.value)
      get("Article", `Get?id=${this.value}`).then((x) => {
          this.selected = x.data;
      });

    this.setFocus();
  },
  methods: {
    setFocus(forceFocus) {
          if (forceFocus || !this.value) {
              this.$nextTick(() => {
                  this.$refs.vms.open = true;
                  this.$refs.vms.$el.children[0].children[0].children[0].focus();
              });
          }
    },
    onSearch: debounce(
      function (value) {
        const trimmed = value.trim();
        if (trimmed.length > 0) this.getArticles(trimmed);
      },
      250,
      false
    ),
    async getArticles(query) {
      await get("Article", `Search?query=${encodeURIComponent(query)}`).then(
        (x) => (this.articles = x.data)
      );
    },
    onSelect() {
      this.$emit("input", this.selected.id);
      // Emits service need.
      this.$emit("update:serviceNeed", this.selected.serviceNeed);
      // Emits article name.
      this.$emit("update:article", this.selected);
    }
  }
};
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}

.v-select.vs--single.vs--searchable {
  min-width: 100%;
  background: white;
}

.v-select .vs__dropdown-toggle {
  min-height: 38px;
}

.vs__selected {
  min-height: 100%;
}
</style>