'use strict';

export const isInViewport = function (el) {
  const bounding = el.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const scrolledToEnd = function (el, offset, direction) {
  let scrollLeftPrev = 0;
  var newScrollLeft = el.scrollLeft,
    width = el.width,
    scrollWidth = el.scrollWidth;

  let isAtEnd = false;
  if (direction === 'right') {
    if (scrollWidth - newScrollLeft - width === offset) {
      alert('right end');
      isAtEnd = true;
    }
  }
  if (direction === 'left') {
    if (newScrollLeft === 0) {
      alert('left end');
      isAtEnd = true;
    }
  }

  scrollLeftPrev = newScrollLeft;

  return isAtEnd;
};
