<template>
    <div>
        <b-table :items="eventLogs"
                 :fields="fields"
                 :sort-by.sync="sortBy"
                 :sort-desc="false"
                 hover
                 small
                 striped>
            <template #cell(message)="row">
                <span :id="'popover-' + row.item.id"
                      v-b-popover.hover.top="row.item.displayText">
                    {{ row.item.message }}
                </span>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';

    export default {
        props: {
            entityType: {
                type: Number,
                required: true
            },
            entityId: {
                type: [String, Number],
                required: true
            },
            type: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                eventLogs: [],
                sortBy: 'displayDate',
                fields: [
                    { key: 'displayDate', label: 'Datum', sortable: true, },
                    { key: 'message', label: 'Meddelande' },
                ]
            };
        },
        mounted() {
            this.fetchEventLogs();
        },
        methods: {
            fetchEventLogs() {
                get(`EventLog`, `Get`, {
                    params: {
                        entityType: this.entityType,
                        entityId: this.entityId,
                        type: this.type
                    },
                })
                    .then(response => {
                        this.eventLogs = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    };
</script>

<style scoped>
    .table {
        font-size: small;
    }
</style>